export const isMacOS = () => {
  if (typeof window === "undefined")
    return false

  return navigator.appVersion.includes("Mac") ||
    navigator.appVersion.includes("iPad")
}

export const isIPhone = () => {
  if (typeof window === "undefined")
    return false

  return navigator.appVersion.includes("iPhone")
}

export const isWindow = () => {
  if (typeof window === "undefined")
    return false

  return navigator.appVersion.includes("Win")
}

export const isLinux = () => {
  if (typeof window === "undefined")
    return false

  return navigator.appVersion.includes("Linux")
}

export const getOS = () => {
  if (isIPhone())
    return 'ios'
  else if (isMacOS())
    return 'macos'
  else
    return 'window'
}